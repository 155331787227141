import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { CONST_ROUTE_PATH } from '../../routes/Routes'

export const ContactBadge = () => {
  const navigate = useNavigate()
  return (
    <Box sx={{ width: "100%", backgroundColor: "black", }}>
      <Box sx={{ p: "2rem", color: "white", display: "flex", alignItems: 'center', justifyContent: "center", gap: 2 }}>
        <Typography fontStyle="italic" fontWeight={550} sx={{fontSize:{md:"2rem"}}} variant='h6'>
          Reach Out to Us
        </Typography>
        <Button
          component={Link}
          to={CONST_ROUTE_PATH.CONTACT}
          sx={{
            backgroundColor: "red",
            color: "white",
            padding: {md:"0.5rem 2rem"},

            fontStyle:"italic",
            fontWeight:550,
            "&:hover": {
              backgroundColor: "#FF5C1A", // Optional hover color adjustment
            }
          }}
        >
          Contact us
        </Button>
      </Box>
    </Box>
  )
}
