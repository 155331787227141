import React from 'react';
import { Box, Typography, Grid, Card, CardContent } from '@mui/material';
import SecurityIcon from '@mui/icons-material/Security';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import SettingsIcon from '@mui/icons-material/Settings';
import GavelIcon from '@mui/icons-material/Gavel';
import GroupIcon from '@mui/icons-material/Group';
import LaptopIcon from '@mui/icons-material/Laptop';
import VerifiedIcon from '@mui/icons-material/Verified';

const companyValues = [
  {
    icon: <SecurityIcon fontSize="3rem" sx={{ color: '#FF6F2F' }} />,
    title: 'Expert Cybersecurity Instructors',
    description: `Learn from highly experienced cybersecurity professionals with real-world expertise. Our instructors 
    possess in-depth knowledge across various cybersecurity domains, including Vulnerability Assessment, Penetration Testing, 
    and ISO 27001 Compliance. They have worked on major cybersecurity projects, contributing to both private and government 
    sectors, bringing firsthand insights into the current threat landscape. You will be guided step-by-step, gaining industry 
    insights that go beyond textbooks and theory, ensuring you're fully prepared to tackle modern security challenges.`,
  },
  {
    icon: <SupportAgentIcon fontSize="3rem" sx={{ color: '#FF6F2F' }} />,
    title: 'Flexible Learning Paths',
    description: `Study cybersecurity on your own terms with our flexible learning programs designed to accommodate your busy 
    schedule. Whether you're a full-time working professional or a student, our self-paced modules allow you to learn from any 
    location, accessing content 24/7. The courses are carefully crafted to ensure seamless integration into your daily routine, 
    with interactive lessons, video tutorials, and live support. You can choose to fast-track your learning or take your time 
    to absorb complex topics, offering ultimate control over your educational journey.`,
  },
  {
    icon: <SettingsIcon fontSize="3rem" sx={{ color: '#FF6F2F' }} />,
    title: 'Hands-on Security Projects',
    description: `Engage in real-world, hands-on projects that mirror the challenges faced in modern cybersecurity environments. 
    Our courses provide practical cybersecurity exercises such as performing vulnerability scans, ethical hacking, and implementing 
    critical security controls. You'll work with industry-standard tools like Nessus, Metasploit, and Wireshark, enhancing your 
    technical expertise through hands-on practice. These projects simulate real-life scenarios that you’ll encounter in the workplace, 
    providing you with the experience to confidently apply your skills on the job.`,
  },
  {
    icon: <GavelIcon fontSize="3rem" sx={{ color: '#FF6F2F' }} />,
    title: 'Cybersecurity Certifications',
    description: `Upon completing our courses, you’ll be awarded industry-recognized certifications that validate your cybersecurity 
    knowledge and technical skills. These certifications cover key areas like penetration testing, security operations, compliance 
    (e.g., ISO 27001), and ethical hacking. Having these credentials will distinguish you in the job market and highlight your 
    qualifications to employers, paving the way for career advancement in this fast-growing field. Additionally, our certification 
    programs are aligned with current industry standards, ensuring your skills are relevant and up-to-date.`,
  },
  // {
  //   icon: <GroupIcon fontSize="3rem" sx={{ color: '#FF6F2F' }} />,
  //   title: 'Community and Collaboration',
  //   description: `Join a vibrant community of like-minded professionals, instructors, and cybersecurity enthusiasts. Through 
  //   our collaborative platform, you’ll be able to engage in discussions, share insights, and work on group projects with peers 
  //   from around the globe. Our instructors are also actively involved in the community, providing mentorship and guidance beyond 
  //   the coursework. This collaborative environment fosters growth, networking, and the opportunity to learn from others’ experiences, 
  //   helping you build valuable connections in the cybersecurity field.`,
  // },
  // {
  //   icon: <LaptopIcon fontSize="3rem" sx={{ color: '#FF6F2F' }} />,
  //   title: 'Cutting-edge Course Materials',
  //   description: `Our cybersecurity courses are constantly updated to reflect the latest developments in the field. With threats 
  //   evolving rapidly, we ensure that all learning materials are aligned with current security trends, technologies, and attack 
  //   methodologies. You'll have access to in-depth resources, including detailed guides, real-time demonstrations, and case studies 
  //   from actual incidents. Our goal is to equip you with the knowledge to combat emerging threats and stay ahead of the curve in 
  //   this ever-changing landscape.`,
  // },
  // {
  //   icon: <VerifiedIcon fontSize="3rem" sx={{ color: '#FF6F2F' }} />,
  //   title: 'Accredited by Industry Leaders',
  //   description: `Our programs are accredited and recognized by leading organizations in the cybersecurity sector. We partner 
  //   with industry bodies to ensure our curriculum meets the highest standards of quality and relevance. Through these partnerships, 
  //   our courses incorporate best practices and real-world applications, helping you build a solid foundation in cybersecurity that 
  //   is trusted by employers worldwide. Accreditation from renowned organizations adds extra value to your credentials, further 
  //   boosting your employability.`,
  // },
];


const CompanyValue = () => {
  return (
    <Box sx={{ backgroundColor: 'white', padding: '25px 20px', minHeight: '60vh' }}>
      <Box sx={{
        p: { xs: '0.5rem', lg: '1rem 9rem' }
      }}>

        <Box>
          <Typography textAlign={{xs:"start",md:"center"}}  fontWeight={600} color="#0F2A47" variant="h3" sx={{ fontSize: { xs: "1.6rem", md: "2.5rem" } }} gutterBottom>
            Why Learn Cybersecurity with Us?
          </Typography>
          <Typography textAlign="center" fontWeight={600} color="gray" fontSize={{xs:"16px", md:"20px"}} variant="h6" gutterBottom>
            Unlock your cybersecurity potential with expert-led courses, flexible schedules, and hands-on projects. Gain industry-recognized certifications to accelerate your career in the fast-evolving security landscape.
          </Typography>

        </Box>

        <Grid container spacing={4} mt={2} justifyContent="center" >
          {companyValues.map((value, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <Card
                sx={{
                  height: '100%',
                  textAlign: 'center',
                  // padding: '0.5rem',
                  backgroundColor: '#0F2A47',
                  color: '#fff',
                  // borderRadius: '12px',
                  boxShadow: 3,
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: '0 4px 20px rgba(0,0,0,0.2)',
                  },
                }}
              >
                <CardContent>
                  <Box sx={{ fontSize: "4rem" }}>{value.icon}</Box>
                  <Typography variant="h6" fontWeight={550} gutterBottom>
                    {value.title}
                  </Typography>
                  <Typography textAlign="start" ml={2} color='gray' variant="body2">{value.description}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

    </Box>
  );
};

export default CompanyValue;
