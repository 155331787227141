import React from 'react';
import { Box, Typography, Grid, Card, CardMedia, CardContent, Button, Container, CardActions } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Header from './common/Header';
import { ContactBadge } from './common/ContactBadge';
import { Footer } from './common/Footer';
import { freeCourses } from '../mock/Cousedata';

// Sample free courses data


const FreeCourses = () => {
    const navigate = useNavigate();

    const handleCourseClick = (id) => {
        // Navigate to the course details page based on course ID
        navigate(`/free-courses/${id}`);
    };

    return (
        <>
            <Header />
            <Box sx={{ backgroundColor: 'white', padding: '25px 20px' }}>
                <Container>
                    <Typography
                        textAlign="center"
                        fontFamily="sans-serif"
                        fontWeight={600}
                        color="#0F2A47"
                        variant="h3"
                        gutterBottom
                    >
                        Free Courses
                    </Typography>
                    <Typography textAlign="center" color="gray" sx={{ mb: 3 }}>
                        Explore our free courses and start learning today!
                    </Typography>

                    <Grid container spacing={4} justifyContent="center">
                        {freeCourses.map((course) => (
                            <Grid item xs={12} sm={6} md={4} key={course.id}>
                                <Card sx={{ maxWidth: 500, minHeight: "50vh", cursor: 'pointer' }}>
                                    <CardMedia
                                        component="img"
                                        height="200"
                                        image={course.image}
                                        alt={course.title}
                                    />
                                    <CardContent>
                                        <Box height={"155px"}>
                                            <Typography variant="h5" fontWeight="bold" gutterBottom>
                                                {course.title}
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary">
                                                {course.description}
                                            </Typography>
                                        </Box>


                                        {/* This Box with flexGrow ensures content above the button takes up space, pushing the button down */}
                                        <Box sx={{ flexGrow: 1 }} />

                                        {/* CardActions aligns the button at the bottom of the card */}
                                        <CardActions>
                                            <Button
                                                variant="contained"
                                               
                                                sx={{ mt: 2, bgcolor:"#0F2A47" }} // Make button full width if desired
                                                onClick={() => handleCourseClick(course.id)}
                                            >
                                                View Course
                                            </Button>
                                        </CardActions>
                                    </CardContent>

                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Container>

            </Box>
            <ContactBadge />
            <Footer />

        </>
    );
};

export default FreeCourses;
