import React from 'react';
import { Box, Typography, Button, Card, CardContent, Container } from '@mui/material';
import ReactPlayer from 'react-player';
import { CONST_ROUTE_PATH } from '../../../routes/Routes';
import { useNavigate } from 'react-router-dom';

const FreeVideoCard = () => {
    const navigate = useNavigate()
    return (
        <Box
            sx={{
                display:"flex",
                alignItems: 'center',
                justifyContent: 'center',
                padding: '20px',
                mb:4,
                // border:"2px solid red",

                background: 'white',
                borderRadius: '16px',
                backgroundColor: "white",
                // boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
            }}
        >
            <Container
                sx={{

                    display: 'flex', flexDirection: { xs: "column", md: "row" }, padding: { xs: "0rem", md: "2rem" }, justifyContent:{xs:"center", md:"space-around"}
                }}>
                <Box>

                    <Typography variant='h4' mt={{xs:2}} mb={{xs:3,md:5}} fontWeight={550} color='#0F2A47'>
                        Our Free Courses
                    </Typography>

                    {/* Video Section */}
                    <Box
                        sx={{
                            width: { xs: "100%", md: '30vw' },
                            height: {xs:"30vh", md:'40vh'},
                            // borderRadius: {xs:"0px",md:'16px'},
                            overflow: 'hidden',
                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',

                        }}
                    >
                        <ReactPlayer
                            url="https://www.youtube.com/watch?v=y1dmSqtlou8"
                            width="100%"
                            height="100%"
                            controls
                            style={{ boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', }}
                        />
                    </Box>
                </Box>

                {/* Content Section */}
                <Card
                    sx={{
                        marginLeft: {md:'20px'},
                       
                        mt: { xs: "1rem",md: "0rem"},
                        // borderRadius: '16px',
                        backgroundColor: '#0F2A47',
                        width:"100%",
                        maxWidth: {md:'400px'},

                        color: 'white',
                        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <CardContent sx={{padding:"2rem 1.8rem"}}>
                        <Typography
                            variant="h5"
                            component="div"
                            sx={{ fontWeight: 'bold', }}
                        >
                            Unlock Free Cybersecurity Courses!
                        </Typography>
                        <Typography
                            variant="body2"
                            sx={{ marginY: '10px' , color:"gray" }}
                        >
                            Explore our comprehensive range of free cybersecurity courses designed by industry experts:
                            <ul style={{listStyle:"none",}}>
                                <li><strong>Vulnerability Assessment (VA) Training:</strong> Learn how to identify and mitigate security risks across your systems.</li>
                                <li><strong>Penetration Testing (PT) Training:</strong> Gain hands-on skills to ethically hack and protect vulnerable systems.</li>
                                <li><strong>ISO 27001:2022 Compliance Course:</strong> Master the essential knowledge to ensure your organization complies with the latest ISO standards.</li>
                            </ul>
                            Our courses provide in-depth training and practical exercises, empowering you to safeguard digital environments with confidence. Get certified and stay ahead in the fast-evolving world of cybersecurity.
                        </Typography>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: '#FF6F2F',
                                mt: 3,
                                textTransform: 'none',
                                display: 'block',
                                marginLeft: 'auto',  // This pushes the button to the right
                                marginRight: 0,      // Optional, ensures there is no extra margin on the right side
                            
                            }}
                            onClick={() => navigate(CONST_ROUTE_PATH.FREECOURSES)}
                        >
                            Explore More Courses
                        </Button>
                    </CardContent>


                </Card>
            </Container>
        </Box>
    );
};

export default FreeVideoCard;
