import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQComponent = () => {
  const faqs = [
    {
      question: 'What cybersecurity courses do you offer?',
      answer: 'We offer a range of cybersecurity courses, including Vulnerability Assessment, Penetration Testing, ISO 27001 Compliance, and Security Awareness. Each course is designed to provide in-depth knowledge and hands-on skills in protecting information systems.',
    },
    {
      question: 'Do I need prior cybersecurity knowledge to enroll?',
      answer: 'Some of our cybersecurity courses are beginner-friendly and do not require prior knowledge. However, advanced courses like Penetration Testing may require foundational knowledge in networking and basic security concepts. Prerequisites are clearly mentioned on each course’s page.',
    },
    {
      question: 'How are cybersecurity courses structured?',
      answer: 'Our cybersecurity courses are structured with interactive modules that include video lessons, case studies, practical labs, and assessments. This structure helps reinforce your understanding of security concepts and ensures you apply them in real-world scenarios.',
    },
    {
      question: 'Will I receive a certification upon completion?',
      answer: 'Yes, upon successfully completing a cybersecurity course and passing all assessments, you will receive a certificate of completion. This certification validates your skills and can be shared on platforms like LinkedIn.',
    },
    {
      question: 'Can I get mentorship for cybersecurity courses?',
      answer: 'Yes, mentorship is available for select cybersecurity courses. In addition, our support team and community forums are always ready to assist you with any questions or challenges during your learning journey.',
    },
    {
      question: 'What career opportunities are available after completing cybersecurity courses?',
      answer: 'Cybersecurity skills are in high demand, and completing our courses can lead to roles such as Security Analyst, Penetration Tester, Security Engineer, and Compliance Officer. Our courses are designed to prepare you for certifications like CompTIA Security+ and CISSP.',
    },
    {
      question: 'What tools or technologies will I learn in cybersecurity courses?',
      answer: 'You will learn to use various tools commonly used in the industry, such as Wireshark, Nmap, Metasploit, Burp Suite, Nessus, and more. These tools are essential for tasks like vulnerability assessments, penetration testing, and network security analysis.',
    },
    {
      question: 'Do your cybersecurity courses cover compliance standards?',
      answer: 'Yes, we offer specific courses on cybersecurity compliance, including ISO 27001, GDPR, and other data protection regulations. These courses are designed to help you understand and implement security controls to meet compliance requirements.',
    },
    {
      question: 'Are there any hands-on labs in cybersecurity courses?',
      answer: 'Yes, our courses include hands-on labs where you can apply the concepts you learn in real-world scenarios. These labs simulate attacks and defenses, helping you gain practical experience in securing systems and networks.',
    },
    {
      question: 'How long does it take to complete a cybersecurity course?',
      answer: 'Course durations vary depending on the complexity and depth of the subject. Some beginner courses can be completed in a few weeks, while advanced courses like Penetration Testing or ISO 27001 Compliance may take longer to finish. You can learn at your own pace with most of our courses being self-paced.',
    },
  ];
  
  

  return (
    <Box sx={{ maxWidth: '100%', margin: 'auto',bgcolor:"#FFF5EE",  p: { xs:'0.5rem', lg: '3rem 9rem' }
}}>
      <Typography variant="h4"  sx={{fontSize:{xs:"2rem" , md:"2.5rem"}}} fontStyle="italic" align="left" mb={{md:5}} ml={3} color="#FF6F2F" fontWeight={600} gutterBottom>
        Frequently Asked Questions
      </Typography>
      {faqs.map((faq, index) => (
        <Accordion key={index} sx={{ boxShadow: 1, borderRadius: 2, margin: '0.5rem 0', }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#FF6F2F' }} />}>
            <Typography fontFamily="sans-serif" variant="h6" fontWeight="500" color="#333">
              {faq.question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1" color="textSecondary">
              {faq.answer}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default FAQComponent;
