import React from 'react';
import { Box, Grid, Typography, Link, IconButton, Container } from '@mui/material';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'; // Import the arrow icon

export const Footer = () => {

  const menuItems = [
    "Vulnerability Assessment (VA) Training",
    "Penetration Testing (PT) Training",
    "ISO 27001:2022 Compliance Course",
    "Risk Assessment Service Course",
    "Virtual CISO Training",
    "DPDP Act Compliance Training",
    "ISO 27701 Course"
];
const menuItems2 =[
  "SOC 2 Compliance Course",
  "SEBI CSCRF Framework Course",
  "RBI Compliance Course",
  "Aadhaar Compliance Training",
  "IRDA ISNP Training",
  "SEBI Cloud Adoption Framework Course",
  "Gap Analysis Training",


]
  return (
    <>
      <Box
        sx={{
          backgroundColor: "black",
          color: 'white',
          padding: '3rem 0',
          borderTop: '1px solid #fff',
        }}
      >
        <Container>
          <Grid container spacing={5} justifyContent="space-between">
            {/* Company Info */}
            <Grid item xs={12} md={3}>
                    
                    <Box sx={{ textAlign: 'center', lineHeight: 1.2 }}>
                        <Typography variant='h6' color='white' sx={{ fontStyle: 'italic', fontWeight: 550, fontSize: "1rem" }}>INSTITUTE OF</Typography>
                        <hr />
                        <Typography variant='h6' color='white' sx={{ fontStyle: 'italic', fontWeight: 550, fontSize: "1rem" }}>CYBER SECURITY</Typography>
                    </Box>

              <Typography mt={1} variant="body2" sx={{ maxWidth: '250px' }}>
                ICS was formed with a mission to provide cutting-edge cybersecurity education and solutions. Our goal is to empower professionals and organizations to safeguard their digital assets and stay ahead of emerging cyber threats.
              </Typography>
              <Link href="/about" color="inherit" underline="none" sx={{ color: '#FF6F2F' }}>
                Read More...
              </Link>
              <Box mt={2}>
                <IconButton href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" sx={{ color: '#fff' }}>
                  <FacebookIcon />
                </IconButton>
                <IconButton href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" sx={{ color: '#fff' }}>
                  <TwitterIcon />
                </IconButton>
                <IconButton href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" sx={{ color: '#fff' }}>
                  <LinkedInIcon />
                </IconButton>
                <IconButton href="https://www.youtube.com/results?search_query=infocus-it" target="_blank" rel="noopener noreferrer" sx={{ color: '#fff' }}>
                  <YouTubeIcon />
                </IconButton>
                <IconButton href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" sx={{ color: '#fff' }}>
                  <InstagramIcon />
                </IconButton>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
                            <Typography variant="h6" sx={{ color: 'red', fontWeight: 'bold' }}>Trending Courses</Typography>
                            {menuItems.map((item, index) => (
                                <Link key={index} href="#" color="inherit" underline="none" sx={{ display: 'flex', alignItems: 'center', padding: '0.5rem 0' }}>
                                    <ArrowForwardIosIcon sx={{ fontSize: '0.75rem', marginRight: '0.5rem' }} />
                                    <Typography variant="body2">{item}</Typography>
                                </Link>
                            ))}
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Typography variant="h6" sx={{ color: 'red', fontWeight: 'bold' }}>Special Courses</Typography>
                            {menuItems2.map((item, index) => (
                                <Link key={index} href="#" color="inherit" underline="none" sx={{ display: 'flex', alignItems: 'center', padding: '0.5rem 0' }}>
                                    <ArrowForwardIosIcon sx={{ fontSize: '0.75rem', marginRight: '0.5rem' }} />
                                    <Typography variant="body2">{item}</Typography>
                                </Link>
                            ))}
                        </Grid>

            {/* Career Tracks */}
            {/* <Grid item xs={12} md={2.8}>
              <Typography variant="h6" sx={{ color: 'red', fontWeight: 'bold' }}>
                Free Resources
              </Typography>
              <Link href="#" color="inherit" underline="none" sx={{ display: 'flex', alignItems: 'center', padding: '0.5rem 0' }}>
                <ArrowForwardIosIcon sx={{ fontSize: '0.75rem', marginRight: '0.5rem' }} />
                <Typography variant="body2">Free Courses
                </Typography>
              </Link>
              <Link href="#" color="inherit" underline="none" sx={{ display: 'flex', alignItems: 'center', padding: '0.5rem 0' }}>
                <ArrowForwardIosIcon sx={{ fontSize: '0.75rem', marginRight: '0.5rem' }} />
                <Typography variant="body2">Blogs</Typography>
              </Link>
              <Link href="#" color="inherit" underline="none" sx={{ display: 'flex', alignItems: 'center', padding: '0.5rem 0' }}>
                <ArrowForwardIosIcon sx={{ fontSize: '0.75rem', marginRight: '0.5rem' }} />
                <Typography variant="body2">Security Awareness</Typography>
              </Link>
              <Link href="#" color="inherit" underline="none" sx={{ display: 'flex', alignItems: 'center', padding: '0.5rem 0' }}>
                <ArrowForwardIosIcon sx={{ fontSize: '0.75rem', marginRight: '0.5rem' }} />
                <Typography variant="body2">Documents</Typography>
              </Link>
              <Link href="#" color="inherit" underline="none" sx={{ display: 'flex', alignItems: 'center', padding: '0.5rem 0' }}>
                <ArrowForwardIosIcon sx={{ fontSize: '0.75rem', marginRight: '0.5rem' }} />
                <Typography variant="body2">Phishing</Typography>
              </Link>
              <Link href="#" color="inherit" underline="none" sx={{ display: 'flex', alignItems: 'center', padding: '0.5rem 0' }}>
                <ArrowForwardIosIcon sx={{ fontSize: '0.75rem', marginRight: '0.5rem' }} />
                <Typography variant="body2">Case Studies</Typography>
              </Link><Link href="#" color="inherit" underline="none" sx={{ display: 'flex', alignItems: 'center', padding: '0.5rem 0' }}>
                <ArrowForwardIosIcon sx={{ fontSize: '0.75rem', marginRight: '0.5rem' }} />
                <Typography variant="body2">Certifications</Typography>
              </Link>
            </Grid> */}

            {/* Trending Certifications */}
           

            {/* Customer Service */}
            <Grid item xs={12} md={2.2}>
              <Typography variant="h6" sx={{ color: 'red', fontWeight: 'bold' }}>
                Company
              </Typography>
              <Link href="/about" color="inherit" underline="none" sx={{ display: 'flex', alignItems: 'center', padding: '0.5rem 0' }}>
                <ArrowForwardIosIcon sx={{ fontSize: '0.75rem', marginRight: '0.5rem' }} />
                <Typography variant="body2">About Us</Typography>
              </Link>
              <Link href="/contact-us" color="inherit" underline="none" sx={{ display: 'flex', alignItems: 'center', padding: '0.5rem 0' }}>
                <ArrowForwardIosIcon sx={{ fontSize: '0.75rem', marginRight: '0.5rem' }} />
                <Typography variant="body2">Contact Us</Typography>
              </Link>
              <Link href="#" color="inherit" underline="none" sx={{ display: 'flex', alignItems: 'center', padding: '0.5rem 0' }}>
                <ArrowForwardIosIcon sx={{ fontSize: '0.75rem', marginRight: '0.5rem' }} />
                <Typography variant="body2">Privacy Policy</Typography>
              </Link>
              
              <Link href="/faq" color="inherit" underline="none" sx={{ display: 'flex', alignItems: 'center', padding: '0.5rem 0' }}>
                <ArrowForwardIosIcon sx={{ fontSize: '0.75rem', marginRight: '0.5rem' }} />
                <Typography variant="body2">FAQ</Typography>
              </Link>
              <Link href="https://infocusit.in" color="inherit" underline="none" sx={{ display: 'flex', alignItems: 'center', padding: '0.5rem 0' }}>
                <ArrowForwardIosIcon sx={{ fontSize: '0.75rem', marginRight: '0.5rem' }} />
                <Typography variant="body2">Blogs</Typography>
              </Link>
              <Link href="https://lms.icsecurity.in/" color="inherit" underline="none" sx={{ display: 'flex', alignItems: 'center', padding: '0.5rem 0' }}>
                <ArrowForwardIosIcon sx={{ fontSize: '0.75rem', marginRight: '0.5rem' }} />
                <Typography variant="body2">LMS</Typography>
              </Link>
            </Grid>
          </Grid>


        </Container>
        {/* Bottom Copyright */}

      </Box>
      <Box textAlign="center" sx={{ borderTop: '1px solid #444', padding: '1rem', backgroundColor: "red", display: "flex", alignItems: "center", justifyContent: "center" }}>

        <Typography sx={{ color: "white" }} variant="body2">
          Copyright 2024 © ICS All Rights Reserved. |
        </Typography>
        <Link href="#" sx={{ marginLeft: '1rem', color: '#fff' }} underline="none">Partner With Us |</Link>
        <Link href="#" sx={{ marginLeft: '1rem', color: '#fff' }} underline="none">Legal </Link>
      </Box></>
  );
};




