import React, { useState } from 'react';
import {
    Button, Card, CardContent, Grid,
    TextField, Typography, Box,
    keyframes,  CircularProgress
} from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import RegistrationApiService from '../../services/RegistrationApiServices';
import { useAuth } from '../../context/AuthContext';
import { CONST_ROUTE_PATH } from '../../routes/Routes';
import Logo2 from '../../assets/Logo2.png';
import HomeIcon from '@mui/icons-material/Home';


// Validation schema for the sign-up form
const SignUpSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string().min(6, 'Password should be at least 6 characters').required('Password is required'),
    emailOTP: Yup.string().when('otpSent', {
        is: true,
        then: Yup.string().required('OTP is required'),
    }), // OTP validation only when OTP has been sent
});

// Animation for smooth slide-in effect from left to right
const slideIn = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-70px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

function SignUp() {
    const [otpSent, setOtpSent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const {  loginWithApi } = useAuth();

    // const menuItems = [
    //     "Cybersecurity", "Web Development", "Mobile Development", "Data Science",
    //     "Cloud Computing", "Blockchain", "DevOps", "IT Compliance","Artificial Intelligence"
    // ];

    const handleOtpSubmit = async (values) => {
        setLoading(true);
        try {
            await RegistrationApiService.sendOTPs({ email: values.email });
            setOtpSent(true);
            setSuccessMessage('OTP has been sent to your email.');
            setErrorMessage('');
        } catch (error) {
            console.error('Error sending OTP:', error);
            setErrorMessage(error?.response?.data?.message.toString());
            setSuccessMessage('');
        } finally {
            setLoading(false);
        }
    };

    const handleRegistration = async (values) => {
        setLoading(true);
        try {
            const registrationData = {
                email: values.email,
                emailOTP: values.emailOTP,
                password: values.password,
                firstname: values.firstName,
                lastname: values.lastName,
            };

            const response = await RegistrationApiService.verifyOTPs(registrationData);
            if (response) {
                const { token, userId } = response;
                if (token) {
                    loginWithApi(token, userId);
                    localStorage.setItem('email', registrationData.email);
                    navigate(CONST_ROUTE_PATH.USER_DASHBOARD);
                    setSuccessMessage('User registered successfully!');
                    setErrorMessage('');
                }
            }
        } catch (error) {
            console.error('Error registering user:', error);
            setErrorMessage(error.response?.data?.message || 'Failed to register user. Please try again.');
            setSuccessMessage('');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box sx={{ display: 'flex', minHeight: '100vh', }}>



            <Box sx={{
                width: "100%",
                backgroundImage:"url(https://www.enetworks.gy/wp-content/uploads/2020/08/E-Networks-Banner.png)",
                // background: 'linear-gradient(135deg, #004aad, #673ab7)',
                // backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat",
                objectFit: "cover",
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: { xs: "column-reverse", md: 'row' },
                color: 'white',
                textAlign: 'center',
                padding: '2rem',
            }}>
                <Button startIcon={<HomeIcon/>} onClick={()=>navigate(CONST_ROUTE_PATH.HOME)} variant='text' color='success' sx={{position:"absolute", top:30, left:30, color:"white", textTransform:"none"}}>Home</Button>
                <Box sx={{
                    width: { xs: '100%', md: '40%', lg: "30%" },
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'white',
                    p: { xs: 2, md: 0 },
                    boxShadow: 3,
                    animation: `${slideIn} 1s ease-out`,
                }}>
                    
                    <Card sx={{
                        width: "100%",
                        padding: '2rem',

                    }}>
                        <CardContent >
                            <Typography variant="h5" sx={{ fontWeight: 'bold', textAlign: 'center', marginBottom: '2rem' }}>
                                Sign Up !
                            </Typography>
                            <Formik
                                initialValues={{ firstName: '', lastName: '', email: '', password: '', emailOTP: '' }}
                                validationSchema={SignUpSchema}
                                onSubmit={(values) => {
                                    if (!otpSent) {
                                        handleOtpSubmit(values);
                                    } else {
                                        handleRegistration(values);
                                    }
                                }}
                            >
                                {({ errors, touched }) => (
                                    <Form>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6}>
                                                <Field
                                                    as={TextField}
                                                    fullWidth
                                                    name="firstName"
                                                    label="First Name"
                                                    variant="outlined"
                                                    error={touched.firstName && !!errors.firstName}
                                                    helperText={touched.firstName && errors.firstName}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Field
                                                    as={TextField}
                                                    fullWidth
                                                    name="lastName"
                                                    label="Last Name"
                                                    variant="outlined"
                                                    error={touched.lastName && !!errors.lastName}
                                                    helperText={touched.lastName && errors.lastName}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Box mt={2}>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                name="email"
                                                label="Email"
                                                variant="outlined"
                                                error={touched.email && !!errors.email}
                                                helperText={touched.email && errors.email}
                                            />
                                        </Box>

                                        <Box mt={2}>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                name="password"
                                                type="password"
                                                label="Password"
                                                variant="outlined"
                                                error={touched.password && !!errors.password}
                                                helperText={touched.password && errors.password}
                                            />
                                        </Box>

                                        {otpSent && (
                                            <Box mt={2}>
                                                <Field
                                                    as={TextField}
                                                    fullWidth
                                                    name="emailOTP"
                                                    label="Enter OTP"
                                                    variant="outlined"
                                                    error={touched.emailOTP && !!errors.emailOTP}
                                                    helperText={touched.emailOTP && errors.emailOTP}
                                                />
                                            </Box>
                                        )}

                                        {successMessage && <Typography mt={2} color="success">{successMessage}</Typography>}

                                        {errorMessage && <Typography mt={2} color="warning" variant='body1'>{errorMessage}</Typography>}

                                        <Box mt={2}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                disabled={loading}
                                            >
                                                {loading ? <CircularProgress size={24} /> : otpSent ? 'Register' : 'Send OTP'}
                                            </Button>
                                        </Box>
                                    </Form>
                                )}
                            </Formik>

                            <Typography variant='body1' mt={3}>
                                Already have an account? <Link to="/sign-in">Sign in</Link>.
                            </Typography>
                        </CardContent>
                    </Card>
                </Box>
                <Box sx={{ width: { xs: '100%', md: '60%', lg: "60%" },padding:"1rem" ,  textAlign:"center"}}>
                    <img src={Logo2} style={{
                        width: "200px"
                    }} alt="Logo..." />
                    <Typography  fontWeight={550} sx={{
                        color: "#FF6F2F", fontSize: { xs: "2rem", md: "3rem" },
                        animation: `${slideIn} 1s ease-out`,
                    }}>Sign Up for Our Courses</Typography>

                    <Typography  sx={{ fontSize: { xs: "1rem", md: "1.25rem" }, mt: 2, animation: `${slideIn} 1s ease-out` }}>
                        Join our ICS today to gain access to a wide variety of courses
                        in Cybersecurity. Whether you are a beginner or an expert,
                        our platform offers curated content to help you excel.
                    </Typography>

                    {/* <Typography sx={{ fontSize: { xs: "1rem", md: "1.25rem" }, mt: 2, animation: `${slideIn} 1s ease-out` }}>
                        Unlock new skills and certifications by enrolling in premium courses
                        with live mentorship and hands-on projects. Your journey to mastering
                        new technologies starts here.
                    </Typography> */}
                    
                    {/* <Typography variant="h5" sx={{color:"#FF6F2F", fontWeight: 'bold', mt: 3, animation: `${slideIn} 1s ease-out`, }}>
                        Explore Our Courses:
                    </Typography>
                    <Grid  container spacing={2} sx={{ mt: 2,ml:{md:5}, animation: `${slideIn} 1s ease-out`,  }}>
                        {menuItems.map((course, index) => (
                            <Grid item xs={6} md={4}  justifyContent="center" alignItems="center"   key={index}>
                                <Typography textAlign="center" variant="body1" sx={{ fontWeight: 'bold' }}>{course}</Typography>
                            </Grid>
                        ))}
                    </Grid> */}
                    <Typography variant="h5" sx={{color:"#FF6F2F", fontWeight: 'bold', mt: 4, animation: `${slideIn} 1s ease-out`, }}>
                    For support, contact us at:                    </Typography>
                    <Typography variant="body1" sx={{fontWeight:600,mt:2, animation: `${slideIn} 1s ease-out`,}}>
                        Email : ics.delhi41@gmail.com <br />
                        Phone : +91-8178210903
                    </Typography>
                </Box>

            </Box>
        </Box>
    );
}

export default SignUp;
