import React from 'react';
import { Box, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import ReactPlayer from 'react-player';

import { freeCourses } from '../../mock/Cousedata';
import Header from './Header';
import { ContactBadge } from './ContactBadge';
import { Footer } from './Footer';

const FreeCoursesDetails = () => {
    const { id } = useParams();

    // Find the course based on the numeric id
    const course = freeCourses.find((course) => course.id === Number(id));
    console.log(course)
    if (!course) {
        return <Typography>Course not found.</Typography>;
    }

    return (
        <>
        <Header/>
        <Box sx={{ padding: '3rem', textAlign: 'center', display:"flex" }}>
            
            <Box
                sx={{
                    width: '70vw',
                    height: '70vh',
                    // borderRadius: '16px',
                    overflow: 'hidden',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',

                }}
            >
                <ReactPlayer
                    url={course.videoUrl}
                    width="100%"
                    height="100%"
                    controls
                    style={{ boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', }}
                />
            </Box>
            <Box>
            <Typography variant="h4" fontWeight={600} gutterBottom>
                {course.title}
            </Typography>
            </Box>
            
        </Box>
        <ContactBadge/>
        <Footer/>
        </>

    );
};

export default FreeCoursesDetails;
