import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import { CONST_ROUTE_PATH } from '../../../routes/Routes';

const CallToAction = () => {
    const navigate = useNavigate();
    const { apiToken } = useAuth();

    const handleclick =()=>{
        if(apiToken){
            navigate(CONST_ROUTE_PATH.USER_DASHBOARD)
        }else{
            navigate(CONST_ROUTE_PATH.SIGN_IN)
        }
    }
    return (
        <Box  sx={{backgroundColor:"white" , minHeight:"10vh"}}>
            <Box
                sx={{
                    display: 'flex',
                    // flexDirection: 'column',
                    alignItems: 'center',
                    gap:3,
                    justifyContent: 'center',
                    backgroundColor: '#0F2A47', // Light background color
                    padding: '40px 20px',
                    // margin: '40px 0',
                    color:"white"
                }}
            >
                <Typography variant="h4" fontSize={{xs:"1.6rem",md:"2.5rem"}}  gutterBottom>
                    Ready to Start Learning?
                </Typography>
               
                <Button
                onClick={handleclick}
                    variant="contained"
                    color="success"
                    size="large"
                    sx={{
                        fontStyle:"italic",
                        fontWeight:600,
                        // marginTop: 2,

                        padding: '10px',
                        // borderRadius: 2,
                        backgroundColor: "#FF6F2F"
                    }}
                >
                    Get Started for Free
                </Button>
                
            </Box>
            
        </Box>

    );
};

export default CallToAction;
