import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import Header from '../components/common/Header';
import '../components/common/Style.css';
import CompanyValue from '../components/common/content/ComapnyValue';
import { Footer } from '../components/common/Footer';
import CoursesCard from '../components/common/content/CoursesCards';
import HomeVideo from '../assets/HomeVideo.mp4';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { CONST_ROUTE_PATH } from '../routes/Routes';
import CallToAction from '../components/common/content/CallToAction';
import { ContactBadge } from '../components/common/ContactBadge';
import ReviewsList from '../components/common/ReviewCard';
import FAQComponent from '../components/common/FAQComponent';
import CourseSlider from './Courses/CourseSlider';
import CustomBar from '../components/common/CustomBar';
import QuickLinks from '../components/common/content/QuickLinks';
import EsteemedClientele from '../components/common/EsteemedClientele';
import { Typewriter } from 'react-simple-typewriter';
import FreeVideoCard from '../components/common/content/FreeVideoCard';


const Welcome = () => {
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();
  const { apiToken } = useAuth();

  useEffect(() => {
    // Trigger visibility after the component mounts
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 100); // Delay to ensure the animation plays

    return () => clearTimeout(timer);
  }, []);

  const handleclick = () => {
    if (apiToken) {
      navigate(CONST_ROUTE_PATH.USER_DASHBOARD);
    } else {
      navigate(CONST_ROUTE_PATH.SIGN_IN)
    }

  };

  let textArray = ["Elevate Your Expertise with Courses Led by Industry Masters!", "Master the Future of Cybersecurity with Expert-Led Training", "Unlock Elite Cybersecurity Skills with Top Industry Professionals"]

  //http://88.222.214.173:8081/login/index.php
  return (
    <>
      {/* Header Component */}
      <Header />

      {/* Full-screen Video Background */}
      <Box
        sx={{
          position: 'relative',
          height: { xs: "30vh", sm: "40vh", md: '70vh' },
          overflow: 'hidden',
        }}
      >
        <video
          autoPlay
          loop
          muted
          style={{
            position: 'absolute',
            left: 0,
            width: '100vw',
            height: "100%",
            objectFit: "fill",
            zIndex: -1, // Ensure the video is behind the content
          }}
        >
          <source src={HomeVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        {/* Centered Content */}
        <Box
          sx={{
            position: 'absolute',
            top: 10,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center', // Horizontally center
            alignItems: 'center', // Vertically center
            zIndex: 1, // Ensure the content is above the video
          }}
        >
          <Box
            className={isVisible ? 'welcome-slide-in' : ''} // Add the animation class
            maxWidth="md"
            sx={{
              // bgcolor: 'rgba(0, 0, 0, 0.2)', // Changed opacity for better readability
              p: 5,
              borderRadius: 2,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              transition: 'transform 0.5s ease-in-out, opacity 0.5s ease-in-out',
              transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
              opacity: isVisible ? 1 : 0,
              zIndex: 2, // Ensure this content is on top of the video
            }}
          >
            <Typography
              align="start"
              fontWeight={550}
              sx={{
                fontFamily: "sans-serif",
                // fontStyle: "italic",
                color: 'white',

                fontSize: { xs: '1.5rem', sm: '2rem', md: '3em', lg: '3rem' },
              }}
              mb={4}
              gutterBottom
            >
              <Typewriter
                words={textArray}  // Array of texts to be animated
                loop={true}        // Loop through the texts
                typeSpeed={90}     // Typing speed
                deleteSpeed={50}   // Deleting speed
                delaySpeed={1500}  // Delay between words
              /> |

            </Typography>
            <Typography sx={{ color: "gray", fontWeight: 550, display: { xs: "none", sm: "flex" } }} variant="h6" align="left">
              Master Cybersecurity with our industry-focused courses in Vulnerability Assessment, Penetration Testing,  ISO 27001, and more.            </Typography>
            <Button
              onClick={handleclick}
              variant="contained"
              color="success"
              size="large"
              sx={{
                marginTop: { xs: 1, sm: 2, md: 3 },
                padding: '10px 30px',
                borderRadius: 2,
                fontStyle: "italic",
                textTransform: "none",
                fontWeight: 600,
                fontSize: "1rem",
                backgroundColor: "#FF6F2F",
                display: { xs: "none", sm: "flex" },
                transition: "all 0.3s ease-in",
                // transition: 'transform 0.3s ease',
                '&:hover': {
                  backgroundColor: "#0F2A47",
                  transform: 'scale(1.05)',
                  boxShadow: '0 4px 20px rgba(0,0,0,0.2)',
                },
              }}
            >
              Get Started for Free
            </Button>
          </Box>
        </Box>
      </Box>
      <CustomBar />

      {/* Additional content below the video */}
      <CompanyValue />
      {/* <EsteemedClientele/> */}
      <CoursesCard />
      < CallToAction />

      {/* <CourseAdvisors /> */}
      {/* <CourseSlider/> */}

      <FreeVideoCard />

      <FAQComponent />

      <QuickLinks />

      <ReviewsList />
      {/* <Testimonials /> */}
      <ContactBadge />
      <Footer />
    </>
  );
};

export default Welcome;


