export const courses = [
  {
    id: 1,
    title: 'Vulnerability Assessment (VA) Training',
    description: 'Comprehensive training on identifying, evaluating, and addressing system vulnerabilities to enhance security.',
    thumbnail: "https://res.cloudinary.com/upwork-cloud/image/upload/c_scale,w_1000/v1692133843/catalog/1691532646116417536/kbnvizgmzasxaxe2x3vp.jpg",
    category: "Cybersecurity",
    type: 'premium',
    modules: [
      {
        id: 'm1',
        title: 'Module 1: Basics of Vulnerability Assessment',
        description: 'Introduction to vulnerability assessment, including methodologies and tools.',
        videos: [
          {
            id: 'v1',
            title: 'What is Vulnerability Assessment?',
            url: 'https://www.example.com/videos/va-introduction.mp4',
            objective: 'Understand the concept and importance of vulnerability assessment.',
            breakpoints: [
              { time: 5, quiz: { question: "What is the main purpose of vulnerability assessment?", options: ["Identify threats", "Fix vulnerabilities"], correct: "Identify threats" } }
            ]
          },
          {
            id: 'v2',
            title: 'Common Vulnerability Types',
            url: 'https://www.example.com/videos/vulnerability-types.mp4',
            objective: 'Learn about different types of vulnerabilities and their impacts.',
            breakpoints: [
              { time: 8, quiz: { question: "Which of these is a vulnerability type?", options: ["Phishing", "SQL Injection"], correct: "SQL Injection" } }
            ]
          },
        ],
      },
      {
        id: 'm2',
        title: 'Module 2: Tools and Techniques',
        description: 'Deep dive into popular tools and best practices for effective vulnerability assessments.',
        videos: [
          {
            id: 'v3',
            title: 'Top Vulnerability Assessment Tools',
            url: 'https://www.example.com/videos/va-tools.mp4',
            objective: 'Overview of tools like Nessus, Qualys, and OpenVAS.',
            breakpoints: [
              { time: 10, quiz: { question: "Which tool is widely used for vulnerability assessment?", options: ["Nessus", "Wireshark"], correct: "Nessus" } }
            ]
          },
          {
            id: 'v4',
            title: 'Conducting an Assessment',
            url: 'https://www.example.com/videos/conduct-assessment.mp4',
            objective: 'Step-by-step guide on performing a vulnerability assessment.',
            breakpoints: [
              { time: 12, quiz: { question: "What is the first step in conducting an assessment?", options: ["Scanning", "Planning"], correct: "Planning" } }
            ]
          },
        ],
      },
    ],
  },
  {
    id: 2,
    title: 'Penetration Testing (PT) Training',
    description: 'Learn how to simulate real-world attacks to test and improve system defenses.',
    thumbnail: "https://australiancybersecuritymagazine.com.au/wp-content/uploads/2023/08/Appdome.jpg",
    category: "Cybersecurity",
    type: 'premium',
    modules: [
      {
        id: 'm1',
        title: 'Module 1: Introduction to Penetration Testing',
        description: 'Basics of penetration testing, including scope and methodologies.',
        videos: [
          {
            id: 'v1',
            title: 'What is Penetration Testing?',
            url: 'https://www.example.com/videos/pt-introduction.mp4',
            objective: 'Define penetration testing and its role in cybersecurity.',
            breakpoints: [
              { time: 5, quiz: { question: "What does penetration testing assess?", options: ["System weaknesses", "User experience"], correct: "System weaknesses" } }
            ]
          },
          {
            id: 'v2',
            title: 'Phases of Penetration Testing',
            url: 'https://www.example.com/videos/pt-phases.mp4',
            objective: 'Learn about reconnaissance, scanning, exploitation, and reporting.',
            breakpoints: [
              { time: 9, quiz: { question: "Which phase involves gathering information?", options: ["Exploitation", "Reconnaissance"], correct: "Reconnaissance" } }
            ]
          },
        ],
      },
      {
        id: 'm2',
        title: 'Module 2: Tools and Techniques',
        description: 'Explore tools like Metasploit and Burp Suite, and advanced techniques.',
        videos: [
          {
            id: 'v3',
            title: 'Penetration Testing Tools Overview',
            url: 'https://www.example.com/videos/pt-tools.mp4',
            objective: 'Get an overview of essential tools for penetration testing.',
            breakpoints: [
              { time: 10, quiz: { question: "Which tool is used for web app testing?", options: ["Burp Suite", "Nessus"], correct: "Burp Suite" } }
            ]
          },
          {
            id: 'v4',
            title: 'Advanced Techniques in Penetration Testing',
            url: 'https://www.example.com/videos/advanced-techniques.mp4',
            objective: 'Understand advanced methods for bypassing security.',
            breakpoints: [
              { time: 12, quiz: { question: "Which technique involves exploiting unpatched software?", options: ["Zero-day attack", "Brute force"], correct: "Zero-day attack" } }
            ]
          },
        ],
      },
    ],
  },
  {
    id: 3,
    title: 'GRC (Governance, Risk & Compliance) Training',
    description: 'Master the principles of governance, risk management, and regulatory compliance in cybersecurity.',
    thumbnail: "https://www.shutterstock.com/image-photo/boost-efficiency-grc-governance-risk-600nw-2415863671.jpg",
    category: "Cybersecurity",
    type: 'premium',
    modules: [
      {
        id: 'm1',
        title: 'Module 1: Governance Basics',
        description: 'Learn the principles of governance and its role in cybersecurity.',
        videos: [
          {
            id: 'v1',
            title: 'What is Governance in Cybersecurity?',
            url: 'https://www.example.com/videos/governance-intro.mp4',
            objective: 'Understand the governance framework and its importance.',
            breakpoints: [
              { time: 6, quiz: { question: "What does governance ensure?", options: ["Compliance", "Risk identification"], correct: "Compliance" } }
            ]
          },
          {
            id: 'v2',
            title: 'Frameworks for Governance',
            url: 'https://www.example.com/videos/governance-frameworks.mp4',
            objective: 'Introduce frameworks like COBIT and ITIL.',
            breakpoints: [
              { time: 8, quiz: { question: "Which framework focuses on IT governance?", options: ["COBIT", "ISO 27001"], correct: "COBIT" } }
            ]
          },
        ],
      },
      {
        id: 'm2',
        title: 'Module 2: Risk Management',
        description: 'Understand the process of identifying, analyzing, and mitigating risks.',
        videos: [
          {
            id: 'v3',
            title: 'Introduction to Risk Management',
            url: 'https://www.example.com/videos/risk-management.mp4',
            objective: 'Learn the key steps in risk management.',
            breakpoints: [
              { time: 7, quiz: { question: "What is the first step in risk management?", options: ["Risk analysis", "Risk identification"], correct: "Risk identification" } }
            ]
          },
          {
            id: 'v4',
            title: 'Risk Mitigation Strategies',
            url: 'https://www.example.com/videos/risk-mitigation.mp4',
            objective: 'Explore strategies for effective risk mitigation.',
            breakpoints: [
              { time: 10, quiz: { question: "Which is a risk mitigation strategy?", options: ["Avoidance", "Ignorance"], correct: "Avoidance" } }
            ]
          },
        ],
      },
    ],
  },
];
export  const freeCourses = [
  {
    id: 1,
    title: 'ISO 27001:2022 Lead Implementer Course - Expert Guidance',
    description: 'In this video, we ll be providing expert guidance on ISO 27001:2022, the latest standard for lead management.',
    image: 'https://miro.medium.com/v2/resize:fit:1200/1*spCXDMjxmt-cmugK9NXvlg.jpeg',
    videoUrl:'https://www.youtube.com/watch?v=ZdwtY493bvo&list=PLb7GZrDL76Gp8pbWKqTcEee2j5arMWoid',
  },
  {
    id: 2,
    title: 'ISO27001:2022 | Lead Implementor Course | Full Course |ISMS',
    description: 'ISO27001:2022 | Lead Implementor Course | Full Course |ISMS',
    image: 'https://impanix.com/wp-content/uploads/2023/06/w-1200_h-630_m-crop__blog-iso27001-og_11zon.jpg',
    videoUrl:'https://www.youtube.com/watch?v=OqeEkbasXls&list=PLb7GZrDL76Gp8pbWKqTcEee2j5arMWoid&index=2'
  },
  {
    id: 3,
    title: 'ISO27001:2022 Lead Implementor Course | Part-1 | Full Course 2022 Update',
    description: 'ISO27001:2022 Lead Implementor Course | Part-1 | Full Course 2022 Update',
    image: 'https://i.ytimg.com/vi/5oKMf9g-Bo4/hqdefault.jpg',
    videoUrl:"https://www.youtube.com/watch?v=5oKMf9g-Bo4&list=PLb7GZrDL76Gp8pbWKqTcEee2j5arMWoid&index=3"
  },
  {
    id: 4,
    title: 'ISO27001:2022 Lead Implementor Course | Part-2',
    description: 'ISO27001:2022 Lead Implementor Course | Part-2',
    image: 'https://i.ytimg.com/vi/5oKMf9g-Bo4/hqdefault.jpg',
    videoUrl:"https://www.youtube.com/watch?v=Imf7fCtQCbU&list=PLb7GZrDL76Gp8pbWKqTcEee2j5arMWoid&index=4"
  },
  {
    id: 5,
    title: 'ISO27001:2022 Lead Implementer Course | Part-3 | ISMS',
    description: 'ISO27001:2022 Lead Implementer Course | Part-3 | ISMS',
    image: 'https://i.ytimg.com/vi/5oKMf9g-Bo4/hqdefault.jpg',
    videoUrl:"https://www.youtube.com/watch?v=foVkmi9FvOM&list=PLb7GZrDL76Gp8pbWKqTcEee2j5arMWoid&index=6"
  },
  {
    id: 6,
    title: 'ISO27001:2022 LEAD IMPLEMENTOR COURSE | Part-4 | ISMS | 2022 update',
    description: 'ISO27001:2022 LEAD IMPLEMENTOR COURSE | Part-4 | ISMS | 2022 update',
    image: 'https://i.ytimg.com/vi/w4y4_zocqlE/hqdefault.jpg',
    videoUrl:"https://www.youtube.com/watch?v=w4y4_zocqlE&list=PLb7GZrDL76Gp8pbWKqTcEee2j5arMWoid&index=7"
  },


  
  // Add more courses as needed
];